


































































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import Select from '@/components/Input/Select/Select.vue';

const LIST_OPTION_SELECT = [
  {
    title: 'Sem tempo',
    value: 0
  },
  {
    title: '1 hora',
    value: 3600
  },
  {
    title: '1 hora e 30 minutos',
    value: 5400
  },
  {
    title: '2 hora',
    value: 7200
  },
  {
    title: '2 hora e 30 minutos',
    value: 9000
  }
];

interface SelectionOptionsList {
  title: string;
  value: number;
}

interface OptionsSelected {
  component: string;
  time: null | number;
}

@Component({
  components: {
    Modal,
    Select
  }
})
export default class ModalConfigSendRedaction extends Vue {
  @Prop() themeIsSimulated!: boolean;

  private selectionOptionsList: Array<SelectionOptionsList> = LIST_OPTION_SELECT;
  private optionsSelected: OptionsSelected = {
    component: '',
    time: null
  };

  get canStartRedaction() {
    return Object.values(this.optionsSelected).every((option) => option !== null && option !== '');
  }

  @Watch('themeIsSimulated')
  setOptionsTimeSelect() {
    if (this.themeIsSimulated) {
      this.selectionOptionsList = LIST_OPTION_SELECT.filter(
        (option: SelectionOptionsList) => option.title !== LIST_OPTION_SELECT[0].title
      );
    }
  }

  saveTimeOption(selectedOption: number) {
    this.optionsSelected.time = selectedOption;
  }

  startRedaction() {
    this.$store.commit('setRedactionSubmissionSettings', this.optionsSelected);
    this.$emit('begin-redaction');
  }
}
