






























import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal
  }
})
export default class ModalOptionSendRedaction extends Vue {
  startRedaction(component: string) {
    this.$store.commit('setRedactionSubmissionSettings', {
      component,
      time: null
    });

    this.$emit('begin-redaction');
  }
}
