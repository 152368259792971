const LIST_INSTRUCTIONS = [
  {
    text:
      'Um rascunho do seu texto deve ser feito, em local apropriado, para que você saiba se obedeceu ao limite de (no máximo) 30 linhas durante a digitação.'
  },
  {
    text: 'Digite ou cole sua redação na caixa de texto do Explicaê.'
  },
  {
    text:
      'Orientamos que escolha o tamanho da fonte de acordo com sua necessidade. Veja as opções nos links apresentados na página relacionada à correção de redação.'
  },
  {
    text:
      'A redação que apresentar cópia dos textos da Proposta de Redação ou do Caderno de Questões terá o número de linhas copiadas desconsiderado para efeito de correção.'
  },
  {
    text:
      'Após digitar ou colar seu texto, tecle no ícone ENVIAR REDAÇÃO para que sua correção seja feita.'
  },
  {
    text:
      'Acompanhe a página MINHAS REDAÇÕES e leia com atenção as observações e sugestões feitas pelos corretores do Explicaê.'
  },
  {
    text: 'Receberá nota zero, em qualquer das situações expressas a seguir, a redação que:',
    subText: [
      {
        text: '* Desrespeitar os direitos humanos.'
      },
      {
        text: '* Tiver até 7 (sete) linhas escritas, sendo considerada “texto insuficiente”.'
      },
      {
        text: '* Fugir ao tema ou que não atender ao tipo dissertativo-argumentativo.'
      },
      {
        text: '* Apresentar parte do texto deliberadamente desconectada do tema proposto.'
      }
    ]
  }
];

export default LIST_INSTRUCTIONS;
