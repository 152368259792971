
































import { Component, Vue } from 'vue-property-decorator';

import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ContainerInstructionProposal from '@/components/Redaction/ContainerInstructionProposal/ContainerInstructionProposal.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Footer from '@/components/Footer/Footer.vue';

@Component({
  components: {
    ContainerFluid,
    PageHeader,
    BoxContainer,
    ContainerInstructionProposal,
    Tabs,
    Footer
  }
})
export default class InstructionRedaction extends Vue {
  private tabMenu = [{ title: 'Proposta de redação', active: true }];

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get themeIdRedaction() {
    return Number(this.$route.params.themeId);
  }

  goToRedaction() {
    this.$router.push({ name: 'Redaction' });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Redação', to: '/redacao' },
      { title: 'Instruções', to: null }
    ]);
  }
}
