

























import { Vue, Component, Prop } from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import { Redaction } from '../interface/IRedactionDescription';

@Component({
  mixins: [LightBoxImage]
})
export default class ProposalRedaction extends Vue {
  @Prop({ default: false }) tabIsActive!: boolean;
  @Prop({ required: true }) redactionDescription!: Redaction;

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }
}
